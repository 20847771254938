import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  HostBinding,
  OnInit,
  signal,
} from "@angular/core";
import { Subscription } from "rxjs";

import { INotificationEvent } from "../../models";
import { NotificationService } from "../../services/notification.service";
import { slideFromRight } from "../../core-ui.animations";
import { UserService, DeviceService } from "../../services";
import { NotificationTypeEnum, UserRoleEnum } from "../../core-ui.enums";
import { ApplicationService } from "../../services/application.service";

@Component({
  selector: "app-notification-manager",
  templateUrl: "./notification-manager.component.html",
  styleUrls: ["./notification-manager.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [slideFromRight],
})
export class NotificationManagerComponent implements OnInit {
  subscription: Subscription = new Subscription();

  notifications = signal<INotificationEvent[]>([]);

  @HostBinding("class.open") isOpen = false;
  @HostBinding("class.talkdesk") isTalkdeskApp = false;

  // @HostBinding("class") get class(){
  //   return {
  //     'open': this.isOpen,
  //     'talkdesk': this.isTalkdeskApp
  //   }
  // }

  constructor(
    notificationService: NotificationService,
    private userService: UserService,
    application: ApplicationService,
    cd: ChangeDetectorRef
  ) {
    this.subscription.add(
      notificationService.notificationReady$.subscribe((n) => {
        if (n) {
          this.notifications.update((existing) => {
            return [...existing, n];
          });
          // this.notifications = [...this.notifications, n];
          this.isOpen = true;
          cd.detectChanges();
        }
        if (n.getTimeToLive() > 0) {
          setTimeout(() => {
            this.remove(n);
            cd.detectChanges();
          }, n.getTimeToLive());
        }
      })
    );
    this.subscription.add(
      notificationService.notificationClearAll$.subscribe((type) => {
        if (type) {
          this.notifications.update((existing) => {
            return existing.filter((n) => n.getType() !== type);
          });
          // this.notifications = this.notifications.filter(
          //   (n) => n.getType() !== type
          // );
        } else {
          this.notifications.set([]);
          // this.notifications = [];
        }
        cd.detectChanges();
      })
    );
    this.subscription.add(
      notificationService.notificationDismiss$.subscribe((n) => {
        this.remove(n);
        cd.detectChanges();
      })
    );
    this.isTalkdeskApp = application.isTalkdeskApp;
  }

  ngOnInit(): void {}

  trackByFn(index, n: INotificationEvent) {
    return n?.getId();
  }

  remove(notification: INotificationEvent) {
    this.notifications.update((existing) => {
      return existing.filter((n) => n.getId() !== notification.getId());
    });
    // this.notifications = this.notifications.filter(
    //   (n) => n.getId() !== notification.getId()
    // );
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  get isWidget() {
    const isCustomer = this.userService
      .getActiveUser()
      ?.hasRole(UserRoleEnum.customer);
    return DeviceService.isIFrame && isCustomer;
  }

  get isToggleNotificationsVisible() {
    return computed(() => {
      return (
        this.notifications().filter(
          (n) => n.getType() !== NotificationTypeEnum.toast
        ).length > 0
      );
    });
  }
}
