<div
  class="controls"
  [class.no-leave]="!isLeaveAvailable"
  [class.no-center]="!isCenterControlVisible"
  [class.no-share]="this.user.isCustomer"
  [class.size-md]="isSizeMedium"
  [class.size-lg]="isSizeLarge"
>
  <div class="share-mock"></div>
  <div class="center-controls fx-row fx-align-start" #centerControls>
    <div class="control" *ngIf="isAudioDeviceAvailable">
      <button
        [attr.aria-label]="
          isMutedMic ? 'Turn on microphone' : 'Turn off microphone'
        "
        class="mic-button"
        avButton
        ofType="icon"
        [color]="isMutedMic ? 'basic' : 'primary'"
        [size]="size"
        [raised]="true"
        id="btn-mute-mic"
        [disabled]="isMicDisabled()"
        (click)="toggleMute(kindAudio)"
        (keyup)="focus($event)"
        [attr.data-tid]="
          isMutedMic ? 'stream-controls/mic-unmute' : 'stream-controls/mic-mute'
        "
      >
        <av-icon name="mic-mute" mode="dark" *ngIf="isMutedMic"></av-icon>
        <av-icon name="mic" *ngIf="!isMutedMic"></av-icon>
        <div class="volume-wrap">
          <div #volumeElem class="volume"></div>
        </div>
      </button>

      <av-tooltip
        *ngIf="isAudioDeviceMissing || isAudioDevicePermissionMissing"
        class="control-error-tooltip"
        [message]="
          isAudioDeviceMissing ? 'No devices available' : 'Permission required'
        "
      >
        <div class="control-error">!</div>
      </av-tooltip>

      <av-card
        [class.hidden]="isMobile || isOnHold()"
        arrow="bottom"
        [backdrop]="true"
        [menu]="true"
        vAlign="top"
        [popup]="true"
        hAlign="center"
        type="control-options"
      >
        <av-card-body type="slim" [noHeader]="true">
          <av-menu>
            <av-menu-item icon="mic" [arrow]="true" [disabled]="isMutedMic">
              {{ activeAudioDevice.label }}

              <av-card sub-menu [backdrop]="false">
                <av-card-body type="slim">
                  <av-menu>
                    <av-menu-item
                      type="radio"
                      (select)="changeDevice(device)"
                      [checked]="device.deviceId === activeAudioDevice.deviceId"
                      *ngFor="let device of audioDevices"
                      data-tid="stream-controls/change-mic"
                    >
                      <span>{{ device.label }}</span>
                    </av-menu-item>
                  </av-menu>
                </av-card-body>
              </av-card>
            </av-menu-item>

            <div *ngIf="isSpeakerAvailable">
              <av-menu-item type="separator"></av-menu-item>
              <av-menu-item icon="speaker" [arrow]="true">
                {{ activeSpeakerDevice.label }}

                <av-card sub-menu [backdrop]="false">
                  <av-card-body type="slim">
                    <av-menu>
                      <av-menu-item
                        type="radio"
                        (select)="changeDevice(device)"
                        [checked]="
                          device.deviceId === activeSpeakerDevice.deviceId
                        "
                        *ngFor="let device of speakerDevices"
                        data-tid="stream-controls/change-speaker"
                      >
                        <span>{{ device.label }}</span>
                      </av-menu-item>
                    </av-menu>
                  </av-card-body>
                </av-card>
              </av-menu-item>
            </div>

            <div *ngIf="isCaptionAvailable">
              <app-captions-control
                [conferenceId]="conferenceId"
                [istream]="istream"
                [stream]="localStream"
              >
              </app-captions-control>
            </div>

            <av-menu-item type="separator"></av-menu-item>
            <av-menu-item
              [disabled]="isMicDisabled()"
              (select)="toggleMute(kindAudio)"
              [color]="!isMutedMic ? 'danger' : null"
              data-tid="stream-controls/toggle-mic-mute-menu"
            >
              <span *ngIf="!isMutedMic"
                >{{ "Mute mic" | translate }} <span class="keyboard">a</span>
              </span>
              <span *ngIf="isMutedMic"
                >{{ "Unmute mic" | translate }}
                <span class="keyboard">a</span></span
              >
            </av-menu-item>
          </av-menu>
        </av-card-body>
      </av-card>
    </div>

    <!-- [class.control-has-menu]="!isMobile && !isOnHold" [class.control-has-menu-active]="isMutedCam" -->
    <div class="control" *ngIf="isVideoDeviceAvailable">
      <button
        [attr.aria-label]="isMutedCam ? 'Turn on camera' : 'Turn off camera'"
        avButton
        ofType="icon"
        [color]="isMutedCam ? 'basic' : 'primary'"
        [size]="size"
        [raised]="true"
        id="btn-mute-cam"
        (click)="toggleMute(kindVideo)"
        (keyup)="focus($event)"
        [disabled]="isCamDisabled()"
        [attr.data-tid]="
          isMutedCam ? 'stream-controls/cam-unmute' : 'stream-controls/cam-mute'
        "
      >
        <av-icon name="cam" *ngIf="!isMutedCam"></av-icon>
        <av-icon name="cam-mute" mode="dark" *ngIf="isMutedCam"></av-icon>
      </button>

      <av-tooltip
        *ngIf="isVideoDeviceMissing || isVideoDevicePermissionMissing"
        class="control-error-tooltip"
        [message]="
          isVideoDeviceMissing ? 'No devices available' : 'Permission required'
        "
      >
        <div class="control-error">!</div>
      </av-tooltip>

      <av-card
        *ngIf="!isMobile && !isCamDisabled()"
        [class.hidden]="isOnHold()"
        arrow="bottom"
        type="control-options"
        [menu]="true"
        vAlign="top"
        hAlign="center"
        [backdrop]="true"
        [popup]="true"
      >
        <av-card-body type="slim" [noHeader]="true">
          <av-menu>
            <av-menu-item icon="cam" [arrow]="true" [disabled]="isMutedCam">
              {{ activeVideoDevice.label }}

              <av-card sub-menu [backdrop]="false">
                <av-card-body type="slim">
                  <av-menu>
                    <av-menu-item
                      type="radio"
                      (select)="changeDevice(device)"
                      [checked]="device.deviceId === activeVideoDevice.deviceId"
                      *ngFor="let device of videoDevices"
                      data-tid="stream-controls/change-video"
                    >
                      {{ device.label }}
                    </av-menu-item>
                  </av-menu>
                </av-card-body>
              </av-card>
            </av-menu-item>

            <ng-container *ngIf="isFilterAvailable">
              <av-menu-item type="separator"></av-menu-item>
              <!-- [color]="isFilterOn?'danger': null" -->
              <av-menu-item
                icon="background-effect"
                [arrow]="true"
                [disabled]="!isFilterEnabled"
              >
                <!-- *ngIf="!isFilterOn" -->
                <span translate>Effects</span>
                <!-- <span *ngIf="isFilterOn" translate>No Effect</span> -->

                <av-card
                  class="sub-menu-effects"
                  sub-menu
                  [menu]="true"
                  [backdrop]="false"
                >
                  <av-card-body type="slim">
                    <app-background-effects></app-background-effects>
                  </av-card-body>
                </av-card>
              </av-menu-item>
            </ng-container>

            <ng-container *ngIf="isPortraitModeAvailable">
              <av-menu-item type="separator"></av-menu-item>
              <av-menu-item
                [icon]="isPortraitMode ? 'landscape-mode' : 'portrait-mode'"
                (select)="togglePortraitMode()"
                [disabled]="isPortraitModeDisabled"
                data-tid="stream-controls/toggle-portrait-mode"
              >
                <span *ngIf="!isPortraitMode" translate>Portrait mode</span>
                <span *ngIf="isPortraitMode" translate>Default mode</span>
              </av-menu-item>
            </ng-container>

            <av-menu-item type="separator"></av-menu-item>
            <av-menu-item
              [disabled]="isCamDisabled()"
              (select)="toggleMute(kindVideo)"
              [color]="!isMutedCam ? 'danger' : null"
              data-tid="stream-controls/toggle-video-mute-menu"
            >
              <span *ngIf="!isMutedCam">
                {{ "Turn camera off" | translate }}
                <span class="keyboard">v</span>
              </span>
              <span *ngIf="isMutedCam">
                {{ "Turn camera on" | translate }}
                <span class="keyboard">v</span>
              </span>
            </av-menu-item>
          </av-menu>
        </av-card-body>
      </av-card>
    </div>

    <div class="control" *ngIf="isSwitchCamAvailable">
      <button
        arial-label="Switch camera"
        avButton
        ofType="icon"
        color="primary"
        [size]="size"
        [raised]="true"
        id="btn-switch-camera"
        [disabled]="isSwitchCamDisabled()"
        class="btn-switch-cam"
        (click)="switchCam()"
        data-tid="stream-controls/switch-camera"
      >
        <av-icon name="camera-reverse"></av-icon>
      </button>
    </div>

    <!-- <div class="control control-cc" [class.hidden]="!isCaptionVisible" *ngIf="isCaptionAvailable && localStream">
            <app-captions-control [size]="size" [disabled]="isOnHold" [conferenceId]="conferenceId"
                [applicationId]="applicationId" [stream]="localStream">
            </app-captions-control>
        </div> -->

    <div class="control" *ngIf="isHoldEnabled">
      <av-tooltip>
        <button
          aria-label="Put call on hold"
          avButton
          ofType="icon"
          [color]="isOnHold() && isHoldLocal() ? 'basic' : 'primary'"
          [size]="size"
          [raised]="true"
          id="btn-hold"
          [disabled]="isHoldDisabled()"
          (click)="toggleHold()"
          [attr.data-tid]="
            isOnHold() ? 'stream-controls/hold-off' : 'stream-controls/hold-on'
          "
        >
          <ng-container *ngIf="!isOnHold() || (isOnHold() && !isHoldLocal())">
            <av-icon name="pause"></av-icon>
          </ng-container>
          <ng-container *ngIf="isOnHold() && isHoldLocal()">
            <av-icon name="pause" mode="dark"></av-icon>
          </ng-container>
        </button>
        <ng-container tooltip-message>
          <ng-container *ngIf="!isOnHold() || (isOnHold() && !isHoldLocal())">
            {{ "Hold call" | translate }}
            <span class="keyboard keyboard-tooltip">h</span>
          </ng-container>
          <ng-container *ngIf="isOnHold() && isHoldLocal()">
            {{ "Hold call" | translate }}
            <span class="keyboard keyboard-tooltip">h</span>
          </ng-container>
        </ng-container>
      </av-tooltip>
    </div>

    <div
      class="control control-device-capture"
      *ngIf="isDeviceCaptureAvailable"
    >
      <app-display-capture
        [screenStream]="screenStream"
        [size]="size"
      ></app-display-capture>
    </div>

    <div class="control" *ngIf="isFileTransferAvailable()">
      <av-tooltip message="Send a file">
        <button
          aria-label="Send a file"
          avButton
          color="primary"
          ofType="icon"
          [size]="size"
          [raised]="true"
          id="btn-send-file"
          [disabled]="isFileTransferDisabled()"
          (click)="selectFile()"
          data-tid="stream-controls/file-transfer"
        >
          <av-icon name="upload" size="sm"></av-icon>

          <input
            style="display: none"
            #fileInput
            name="file-transfer"
            type="file"
            (change)="fileAdded($event)"
          />
        </button>

        <!-- <av-card
        type="control-options"
        arrow="bottom"
        [popup]="true"
        [class.popup-visible]="true"
        vAlign="top"
        hAlign="center"
        [menu]="true"
      >
        <av-card-body type="slim" [noHeader]="true">
          <av-menu>
            <av-menu-item>
              <span translate>Participant</span>
            </av-menu-item>
          </av-menu>
        </av-card-body>
      </av-card> -->
      </av-tooltip>
    </div>
  </div>

  <av-tooltip
    class="control control-leave"
    [class.control-leave-space]="isCenterControlVisible"
    [enabled]="!user.isAgent"
    message="Leave call"
    *ngIf="isLeaveAvailable"
  >
    <button
      #leave
      class="button-leave"
      aria-label="Leave call"
      avButton
      color="danger"
      ofType="icon"
      [size]="size"
      [raised]="true"
      id="btn-leave-call"
      [disabled]="isOnHold()"
      (click)="leaveConference()"
      (keyup)="leaveKeyUp($event)"
      data-tid="stream-controls/leave-call"
    >
      <av-icon name="phone-down"></av-icon>
    </button>

    <av-card
      type="control-options"
      *ngIf="user.isAgent && !isOnHold()"
      arrow="bottom"
      [popup]="true"
      [class.popup-visible]="isLeavePopupOpen()"
      vAlign="top"
      hAlign="center"
      [menu]="true"
    >
      <av-card-body type="slim" [noHeader]="true">
        <av-menu *ngIf="!hasMultipleAgents()">
          <av-menu-item
            *ngIf="canTerminate"
            icon="turn-off"
            (select)="terminateConference()"
            data-tid="stream-controls/end-call"
          >
            <span translate>End call</span>
          </av-menu-item>
          <av-menu-item
            *ngIf="!canTerminate"
            icon="exit"
            (select)="exitConference()"
            data-tid="stream-controls/leave-call-menu"
          >
            <span translate>Leave call</span>
          </av-menu-item>
        </av-menu>

        <av-menu *ngIf="hasMultipleAgents()">
          <av-menu-item
            icon="turn-off"
            (select)="terminateConference()"
            data-tid="stream-controls/end-for-all"
          >
            <span translate>End call for all</span>
          </av-menu-item>
          <av-menu-item type="separator"></av-menu-item>
          <av-menu-item
            icon="exit"
            (select)="exitConference()"
            data-tid="stream-controls/leave-call-multiple-agents"
          >
            <span translate>Leave call</span>
          </av-menu-item>
        </av-menu>
      </av-card-body>
    </av-card>
  </av-tooltip>
</div>
