<av-tooltip
  *ngIf="isToggleNotificationsVisible()"
  position="left"
  [enabled]="!isOpen"
>
  <button
    [attr.aria-label]="(isOpen ? 'Close' : 'Open') + ' notifications'"
    avButton
    class="notification-toggle"
    ofType="icon"
    size="xs"
    [@slideFromRight]="true"
    color="basic"
    (click)="toggle()"
  >
    <av-icon *ngIf="!isOpen" name="bell"></av-icon>
    <av-icon *ngIf="isOpen" name="arrow-right"></av-icon>
  </button>

  <span
    [attr.aria-label]="notifications().length + ' unread notification(s)'"
    class="notification-counter"
    [class.notification-counter-small]="isWidget"
    *ngIf="!isOpen"
  >
    <ng-container *ngIf="!isWidget">
      {{ notifications().length }}
    </ng-container>
  </span>

  <ng-container tooltip-message>
    <!-- {{ notifications().length }}
    <span *ngIf="notifications().length === 1" translate>{{
      "notification" | translate
    }}</span>
    <span *ngIf="notifications().length > 1" translate>{{
      "notifications" | translate
    }}</span> -->
    {{ "notifications" | translate }}
  </ng-container>
</av-tooltip>
<ng-container *ngFor="let n of notifications(); trackBy: trackByFn">
  <app-notification
    [@slideFromRight]="true"
    *ngIf="isOpen"
    (removed)="remove($event)"
    [notification]="n"
  >
  </app-notification>
</ng-container>
